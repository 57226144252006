import { GetServerSidePropsContext } from 'next/types'
import { useCollections } from '@reservoir0x/reservoir-kit-ui'
import Chain, {
  getServerSideProps as getServerSidePropsForHomePage,
} from './[chain]'
type CollectionSchema = ReturnType<typeof useCollections>['data']

const Home = ({ ssr }: { ssr: { collection: CollectionSchema } }) => (
  <Chain ssr={ssr} />
)

export const getServerSideProps = (context: GetServerSidePropsContext) =>
  getServerSidePropsForHomePage(context)

export default Home
